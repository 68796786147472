import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Наші послуги | Наші послуги автомийки на автомийці Sun Arayc
			</title>
			<meta name={"description"} content={"Пориньте в елегантність - випробуйте наш асортимент послуг з догляду за автомобілем"} />
			<meta property={"og:title"} content={"Наші послуги | Наші послуги автомийки на автомийці Sun Arayc"} />
			<meta property={"og:description"} content={"Пориньте в елегантність - випробуйте наш асортимент послуг з догляду за автомобілем"} />
			<meta property={"og:image"} content={"https://sunarayc.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://sunarayc.com/img/334234244.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://sunarayc.com/img/334234244.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://sunarayc.com/img/334234244.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://sunarayc.com/img/334234244.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://sunarayc.com/img/334234244.png"} />
			<meta name={"msapplication-TileImage"} content={"https://sunarayc.com/img/334234244.png"} />
		</Helmet>
		<Components.Heaeder />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Основні послуги
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Автомийка Sun Arayc пропонує комплекс послуг, покликаних забезпечити вашому автомобілю винятковий догляд, поки ви насолоджуєтеся моментом безтурботності. Від ретельного миття до омолоджуючого догляду за деталями - ми є вашим притулком для спокійного догляду за автомобілем.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://sunarayc.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Наші послуги
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Наша базова мийка використовує м'які поролонові щітки та екологічно чисті шампуні для м'якого видалення бруду, забезпечуючи бездоганний блиск без шкоди для лакофарбового покриття вашого автомобіля.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Варіанти автомийки
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							
							- Безтурботна мийка класу "Делюкс": Підвищіть рівень чистоти вашого автомобіля за допомогою нашої розкішної мийки, яка включає в себе очищення ходової частини та полірування коліс, надаючи вашому автомобілю завершеного та блискучого вигляду.
							<br />
							<br />
- Спокійна мийка з повним спектром послуг: Наш комплексний сервіс пропонує глибоке очищення як зовні, так і всередині, включаючи пилосос, миття вікон та витирання пилу з приладової панелі для загального омолодження та оновлення.

						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Послуги з дезінфекції
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							- Спокійна та чиста обробка салону: Пориньте в чистоту з нашою послугою чистки салону, приділяючи особливу увагу оббивці, килимам і всім куточкам, відновлюючи інтер'єр вашого автомобіля до ідеального стану.
							<br />
							<br />
- Безтурботний догляд за екстер'єром: Захистіть та покращіть зовнішній вигляд вашого автомобіля за допомогою наших послуг з догляду за кузовом, включаючи нанесення воску та полірування, для довготривалого блиску та захисту від атмосферних впливів.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Спеціалізовані процедури
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							- Екологічний віск: Наш екологічно чистий віск не тільки забезпечує захисний шар, але й надає вашому автомобілю сяючого, довготривалого блиску, що відображає нашу прихильність до навколишнього середовища.
							<br/><br/>
- Усунення неприємних запахів: Відчуйте свіжість, як ніколи раніше, з нашою послугою з усунення неприємних запахів, залишаючи ваш автомобіль з приємним і привітним ароматом.

						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
							Додаткові зручності
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							- Розслаблююча зона очікування: Поки ми доглядаємо за вашим автомобілем, розслабтеся в нашій спокійній зоні очікування, призначеній для відпочинку із заспокійливою музикою та зручними сидіннями.
							<br/><br/>
- Гарантія швидкого обслуговування: Розуміючи цінність вашого часу, ми гарантуємо швидке, але ретельне обслуговування, щоб ви могли насолоджуватися чистим автомобілем без очікування.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://sunarayc.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Відчуйте різницю
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Щоб дізнатися більше про наші послуги або запланувати наступний візит, будь ласка, зв'яжіться з нами. Наша команда прагне забезпечити спокійний та високоякісний догляд за автомобілем для вас та вашого транспортного засобу.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});